<template>
  <div class="cadastro-usuario">
    <v-container
      fluid
    >
      <h1 class="titulo"> Cadastro de resultados </h1>

      <div class="box-table">
        <v-skeleton-loader
          :loading="loadingTable"
          :transition="'fade-transition'"
          height="500"
          type="table">
          <v-data-table
            :headers="headers"
            :search="search"
            :items="listaItens"
            width="300px"
            flat
            hide-default-footer
            :loading="loadingPag"
            :items-per-page.sync="itemsPerPage"> 
            <template v-slot:top>
              <BrToolBar @search="buscar" :configFilter="{ listaSelect: objectSelectFilter, getItens: getItens, jsonData: true, filter, order: {'Sweepstakes.dtDraw':'ASC'} }" :addlFilter="true" />
            </template>

            <template v-slot:item.action="{ item }">
              <v-btn
                v-if="permiteAcao($route, 'add')"
                small
                @click="editItem(item)"
                tile
                outlined 
                :color="variables.colorPrimary"
                class="mr-2"
              >
                {{ item.resultRecord ? 'Visualizar Resultado' : 'Cadastrar resultados' }}
              </v-btn>
            </template>

            <template v-slot:footer>
              <div class="wrapper-footer">
                <div class="qtd-paginacao">
                  {{ pageStart }}
                  -
                  {{ pageStop }}
                  de
                  {{ totalItens }}
                </div>
                <v-pagination
                  v-model="page"
                  :length="numberOfPages"
                  circle
                  :disabled="disabled"
                  :total-visible="7"
                  class="box-table-paginacao"
                  v-if="numberOfPages > 1"></v-pagination>
              </div>
            </template>
          </v-data-table>
        </v-skeleton-loader>
      </div>

      <!-- <v-btn class="mx-2 fab-cadastrar-usuario" fab color="primary" @click="cadastrar">
        <v-icon dark>mdi-plus</v-icon>
      </v-btn> -->
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
// import Events from '@/core/service/events'
import { errorSnackbar } from '@/core/service/utils'
// eslint-disable-next-line
import moment from 'moment'
/**
 * MIXIN
 */
import listagemTables from '@/views/mixins/listagemTables'

const namespaceStore = 'results'

export default {
  name: 'CadastroResultados',
  components: {
    BrToolBar: () => import('./components/toolbar')
  },
  mixins: [listagemTables],
  data: () => ({
    objectSelectFilter: {
      'Sweepstakes:id': '',
      'Sweepstakes:cod': '',
      'Sweepstakes:dtStart': '',
      'Sweepstakes:dtEnd': '',
      'Sweepstakes:dtDraw': ''
    },
    headers: [
      { align: 'start', class: 'table-header', text: 'ID', value: 'id' },
      { align: 'start', class: 'table-header', text: 'Tipo de concurso', value: 'ascertainedMethodId' },
      { align: 'start', class: 'table-header', text: 'Código', value: 'cod' },
      { align: 'start', class: 'table-header', text: 'Data de sorteio', value: 'dtDraw' },
      { align: 'end', class: 'table-header', text: '', value: 'action', sortable: false },
    ],
    filter: {'Sweepstakes:automaticDraw_=' : false}
  }),
  mounted() {
    const vm = this
    setTimeout(() => {
      delete vm.filter['Sweepstakes:dtDraw_<']
    }, 1000)
  },
  computed: {
    ...mapGetters(namespaceStore, ['listaItens', 'totalItens']),
    ...mapGetters('sweepstakes', ['item']),
  },

  methods: {
    ...mapActions(namespaceStore, ['buscarItem', 'deletarItem', 'getItem']),
    ...mapActions(namespaceStore, {
      getItens: 'getSweepstakes'
    }),
    ...mapActions('filter', ['setObjFilter']),
    ...mapActions('sweepstakes', {
      // getItens: 'getItens',
      setSweepstakes: 'setItem'
    }),

    editItem (item) {
      window.open (`/gestao/loterias/resultados/cadastro-resultados?sweepstakeId=${item.id}`, 'Cadastro', "width=685 height=490")
      // this.setSweepstakes(item)
      // Events.$emit('cadastro::openModalCadastro', item)
    },
    beforeDestroy () {
        this.clearItens() // limpa os dados da tabela
        this.clearPage() // limpa os dados da paginação
        this.objCampoBusca = [] // limpa os dados do campo de busca
    },
    init () {
       this.getItens({ 
        page: this.page, 
        pageSize: this.$store.getters.ItensPaginacao,
        order: JSON.stringify({'Sweepstakes.dtDraw': 'ASC'}),
        _addlFilter: {
          ...this.filter, 
          'Sweepstakes:dtDraw_>': moment().format('YYYY-MM-DD'),
          'Sweepstakes:dtDraw_<=': moment().add(1, 'days').format('YYYY-MM-DD')
        }}).then(result => {
          if (result.data.totalItens < 1) {
            errorSnackbar('Não há dados disponíveis')
          }
        })
        .catch(err => {
          errorSnackbar(err.error)
        })
        .finally(() => this.loadingTable = false)
    },

    buscar(val) {
      this.loadingPag = true
        .getItens({ _addlFilter: { "cod_ilike":`%${val}%`, ...this.filter } })
        .then(result => {
          if (result.data.totalItens < 1) {
            errorSnackbar('Não há dados disponíveis')
          }
        })
        .catch(err => {
          errorSnackbar(err.error)
        })
        .finally(() => { this.loadingPag = false })
    },
  }
}
</script>

<style lang="scss" src="./styles.scss"></style>>
